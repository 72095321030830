import React from 'react';
import { Layout, Terms } from '../components';

const TermsPage = () => (
  <Layout>
    <Terms />
  </Layout>
);

export default TermsPage;
